var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-root"
  }, [_c('div', {
    staticClass: "error error-4 d-flex flex-row-fluid bgi-size-cover bgi-position-center",
    style: {
      backgroundImage: "url(".concat(_vm.backgroundImage, ")")
    }
  }, [_vm._m(0)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-row-fluid align-items-center align-items-md-start justify-content-md-center text-center text-md-left px-10 px-md-30 py-10 py-md-0 line-height-xs"
  }, [_c('h1', {
    staticClass: "error-title text-success font-weight-boldest line-height-sm"
  }, [_vm._v(" 404 ")]), _c('p', {
    staticClass: "error-subtitle font-size-md-90 text-success font-weight-boldest"
  }, [_vm._v(" ERROR ")]), _c('p', {
    staticClass: "display-4 font-size-md-20 text-danger font-weight-boldest mt-8 mt-md-0 line-height-md"
  }, [_vm._v(" Nothing left to do here. ")])]);

}]

export { render, staticRenderFns }